@charset "utf-8";



// Our variables
$base-font-family: "Helvetica Neue", Helvetica, Arial, sans-serif;
$base-font-size:   16px;
$base-font-weight: 400;
$small-font-size:  $base-font-size * 0.875;
$base-line-height: 1.5;

$spacing-unit:     30px;

$text-color:       #111;
$background-color: #fdfdfd;
$brand-color:      #2a7ae2;

$grey-color:       #828282;
$grey-color-light: lighten($grey-color, 40%);
$grey-color-dark:  darken($grey-color, 25%);

// Width of the content area
$content-width:    800px;

$on-palm:          600px;
$on-laptop:        800px;



// Use media queries like this:
// @include media-query($on-palm) {
//     .wrapper {
//         padding-right: $spacing-unit / 2;
//         padding-left: $spacing-unit / 2;
//     }
// }
@mixin media-query($device) {
    @media screen and (max-width: $device) {
        @content;
    }
}



// Import partials from `sass_dir` (defaults to `_sass`)
@import
	"base",
	"layout"
;

pre, code {
	background-color:#f8f8f8;
}
small code {
	font-size: smaller;
}
.right {
	float:right;
}
.icon:link {
	text-decoration:none;
}
.comment {
	color: $grey-color;
}
@media print {
	.post-content {
		column-count: 2; // doesn't work in Safari..
	}
}
.postscript {
	margin-top:2em;
	font-weight: bold;
	color: $grey-color;
}
// CSS for post-images with hover effect
figure img {
	margin: 0 auto;
}
figure figcaption { 
	text-align: center;
}
figure img.hoover {
	display: none;
}
figure:hover img.non_hoover,
figure:focus img.non_hoover {
	display: none;
}
figure:hover img.hoover,
figure:focus img.hoover {
	display: block;
}
@media print {
	figure figcaption .non_print {
		display: none;
	}
	figure img.hoover,
	figure img.non_hover {
		display: block;
	}
}
